import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { HTag } from '../HTag';

const StyledChefSection = styled.section`
  margin-top: 80px;
  display: flex;
  h1 {
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledImg = styled(Img)`
  width: 300px;
  border: solid 20px #fff;
  margin-right: 50px;
  box-sizing: border-box;
  height: auto;
  display: inline-flex;
  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const StyledChefMessage = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledImageWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;
const MessageFromChef = () => {
  return (
    <StyledChefSection>
      <StyledImageWrapper>
        <StaticQuery
          query={graphql`
            query {
              imageSharp(fluid: { originalName: { eq: "chef.jpg" } }) {
                id
                fluid {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          `}
          render={({ imageSharp: { fluid } }) => {
            return <StyledImg fluid={fluid} />;
          }}
        />
      </StyledImageWrapper>
      <StyledChefMessage>
        <HTag>Message From the Chef</HTag>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sem
          purus, elementum at augue vitae, sollicitudin ultrices metus. Mauris
          viverra, magna eu condimentum tristique, ipsum mauris gravida ligula,
          ac eleifend leo felis et felis. Etiam cursus, orci ut imperdiet
          elementum, ligula urna tempor est, at elementum orci sapien vel ipsum.
          Morbi id mattis mauris. Curabitur convallis faucibus mi aliquet
          maximus. Vestibulum vehicula fermentum ullamcorper.
        </p>
        <p>
          Cras malesuada mi sed nunc vulputate, a feugiat ipsum pretium.
          Vestibulum tellus risus, consequat a nunc at, tincidunt facilisis
          nibh. Maecenas sem lorem, hendrerit at hendrerit in, congue eget arcu.
          Etiam id turpis ac nisl mattis gravida. Ut imperdiet volutpat ipsum,
          eget pretium risus dictum sit amet. Nulla pellentesque erat est,
          finibus cursus quam sollicitudin et. Orci varius natoque penatibus et
          magnis dis parturient montes, nascetur ridiculus mus. In facilisis
          purus mi, ac vestibulum est convallis eget.{' '}
        </p>
      </StyledChefMessage>
    </StyledChefSection>
  );
};

export default MessageFromChef;
