import React, { useContext, useEffect } from 'react';
import SEO from '../components/seo';
import { Layout } from '../components/Layout';
import { FoodSpecials } from '../components/FoodSpecials';
import { MessageFromChef } from '../components/MessageFromChef';
import { LatestSection } from '../components/LatestSection';
import { SiteContext } from '../state';

const IndexPage = () => {
  const { dispatch } = useContext(SiteContext);
  useEffect(() => {
    dispatch({ type: 'SET_ACTIVE_PAGE', payload: 'home' });
  }, []);
  return (
    <>
      <Layout>
        <SEO title="Home" />
        <FoodSpecials />
        <MessageFromChef />
        <LatestSection />
      </Layout>
    </>
  );
};

export default IndexPage;
